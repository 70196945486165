<template>
  <div class="w-full">
    <div class="w-full">
      <BaseInput
        ref="names"
        type="text"
        label="Nombres del representante legal"
        v-model="form.first_name"
        placeholder="Julio Roman"
        required="true"
        :maxlength="25"
      />
      <BaseInput
        ref="last_name"
        type="text"
        label="Apellidos del representante legal"
        v-model="form.last_name"
        placeholder="Henriquez"
        required="true"
        :maxlength="24"
      />
      <!-- Persona Juridica -->
      <div class="w-full">
        <BaseInput
          ref="nit"
          type="text"
          id="nit"
          label="NIT"
          placeholder="4893461894-1"
          v-model="form.identification_number"
          required="true"
          :maxlength="14"
          identification="3"
        />
        <BaseInput
          ref="nit2"
          type="text"
          id="nit2"
          label="Confirmar NIT"
          placeholder="4893461894-1"
          v-model="form.identification_number_confirmation"
          required="true"
          :maxlength="14"
          identification="3"
          @click.native.right.prevent
          @copy.native.prevent
          @paste.native.prevent
        />
        <BaseInput
          ref="company_name"
          type="text"
          id="razonSocial"
          label="Razón social"
          placeholder="Empresa S.A"
          v-model="form.business_name"
          required="true"
          :maxlength="50"
        />
      </div>
      <BaseInput
        type="text"
        phoneNumber
        maxlength="15"
        ref="cellphone"
        label="Número de teléfono"
        placeholder="123412341234"
        v-model="form.phone"
        required="true"
      />
      <div v-if="form.phone !== '' && !validPhone()">
        <p class="mt-5 text-xs text-center text-red-500">{{ feedback }}</p>
      </div>
      <BaseInput
        ref="email"
        type="text"
        label="Correo electrónico"
        v-model="form.email"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :maxlength="50"
      />
      <BaseInput
        ref="email_confirmation"
        type="text"
        label="Confirmar correo electrónico"
        v-model="form.emailConfirmation"
        placeholder="ejemplo@ejemplo.com"
        required="true"
        autocomplete="username"
        :maxlength="50"
        @click.native.right.prevent
        @copy.native.prevent
        @paste.native.prevent
      />
      <BasePassword
        ref="password"
        id="password"
        label="Contraseña"
        v-model="form.password"
        placeholder="**********"
        required="true"
        autocomplete="new-password"
      />
      <BasePassword
        ref="password_confirmation"
        id="verifyPassword"
        label="Confirmar contraseña"
        v-model="form.passwordConfirmation"
        placeholder="**********"
        required="true"
        autocomplete="new-password"
        @click.native.right.prevent
        @copy.native.prevent
        @paste.native.prevent
      />
    </div>
    <div class="w-full">
      <DragDrop
        class="my-5"
        label="Soportes que acrediten las facultades para interponer quejas o reclamos"
        buttonLabel="SELECCIONAR ARCHIVO DE SOPORTE"
        required="true"
        id="authorizationFile"
        :fileList="fileList"
        @files-handler="setFormFiles"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["fileList"],
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        identification_type: 3,
        identification_number: "",
        identification_number_confirmation: "",
        phone: "",
        email: "",
        emailConfirmation: "",
        password: "",
        passwordConfirmation: "",
        business_name: "",
      },
      feedback: "",
    };
  },
  methods: {
    setFormFiles(files) {
      this.$emit("setFiles", files);
    },
    validPhone() {
      if (!this.isValidPhone) {
        this.feedback = "Introduzca un número de teléfono válido";

        return false;
      }
      return true;
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    isValidPhone: function () {
      if (!this.form.phone) return true;
      if (
        this.form.phone.toString().length >= 7 &&
        this.form.phone.toString().length <= 15
      )
        return true;

      return false;
    },
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        this.$emit("setForm", {
          ...newVal,
          email: newVal.email.toLowerCase(),
          emailConfirmation: newVal.emailConfirmation.toLowerCase(),
        });
      },
      deep: true,
    },
  },
};
</script>